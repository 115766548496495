<template>
  <a-layout class="layout">
    <Header />
    <a-layout-content style="padding: 0 50px">
      <div
        :style="{
          // background: '#eff2f6',
          padding: '24px',
          minHeight: '280px',
        }"
      >
        <router-view />
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
import Header from "./components/header.vue";

export default {
  components: {
    Header,
  },
  setup() {},
};
</script>
<style lang="scss" scoped>
.layout {
  // background: radial-gradient(circle, #ccffff 30%, #ffffff 100%);
  // background: #eff2f6;
  // background: linear-gradient(#16161d, #1f1f3a, #3b2f4a);
  width: 100%;
  height: 100%;
  // display: flex;
  background: url("https://assets.floydzero.com/vupimg/12.jpg") no-repeat;
  // background-size: 100% 100%;
  background-position: center center;
  background-attachment: fixed;
  .ant-layout-content {
    overflow: auto;
  }
  .main {
    flex: 1;
  }
}
</style>
