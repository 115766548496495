import axios from "axios";

// const baseURL = "";
import qs from "qs";
import { message } from "ant-design-vue";
// import { useStore } from "vuex";
const intervace = axios.create({
  baseURL: "https://vupapi.floydzero.com/",
  // baseURL: "http://6ae9zz.natappfree.cc",
  timeout: 300000,
});

intervace.interceptors.request.use(
  (config) => {
    // const store = useStore();
    if (config.method === "post") {
      // let data = config.data;
      if (localStorage.getItem("token")) {
        config.headers["Authorization"] = localStorage.getItem("token");
      }
      // config.headers["token"] = localStorage.getItem("token");
      // config.headers["Content-Type"] = "application/x-www-form-urlencoded";
      config.data = qs.stringify(config.data);
    } else {
      config.params = { ...config.params };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

intervace.interceptors.response.use(
  (response) => {
    const { code } = response.data;

    if (code !== 200) {
      message.error("发生错误");
    }

    return response.data;
  },
  (error) => {
    message.error("服务器错误");
    return Promise.reject(error);
  }
);

export default intervace;
