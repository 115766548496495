import { getUserInfo } from "../../api/login/login";
const state = {
  islogin: false,
  userinfo: {
    id: "", // 数据库人
    role: "", // 身份权限
  },
  token: "",
  avauserinfo: {},
};
const mutations = {
  SET_ISLOGIN(state, paylod) {
    state.islogin = paylod;
    localStorage.setItem("islogin", true);
  },
  saveUserinfo(state, paylod) {
    state.userinfo = { ...paylod };
    localStorage.setItem("token", paylod.token);
    localStorage.setItem("userinfo", JSON.stringify(state.userinfo));
  },
  saveavauserinfo(state, paylod) {
    state.avauserinfo = { ...paylod };
  },
  loginOut(state) {
    localStorage.removeItem("token");
    localStorage.removeItem("islogin");
    localStorage.removeItem("userinfo");
    state.islogin = false;
  },
  checkToken(state) {
    state.token = localStorage.getItem("token");

    if (state.token) {
      state.islogin = localStorage.getItem("islogin") || false;
      state.userinfo = JSON.parse(localStorage.getItem("userinfo")) || {
        id: "",
        role: "",
      };
      this.dispatch("Login/getUserInfos");
    }
  },
};
const actions = {
  async getUserInfos({ commit, state }) {
    let { code, data } = await getUserInfo({
      userId: state.userinfo.id,
    });
    if (code === 200) {
      commit("saveavauserinfo", data);
    }
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
