<template>
  <router-view />
</template>
<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    onMounted(() => {
      store.commit("Login/checkToken");
    });
  },
};
</script>
<style>
* {
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
}
#app {
  width: 100%;
  height: 100%;
}
body,
html {
  width: 100%;
  height: 100%;
}
</style>
