import { createRouter, createWebHistory } from "vue-router";
// import Home from "../views/Home.vue";
import store from "../store/index";
import Layout from "../layout/index.vue";
const routes = [
  { path: "/", redirect: "/newhome" },
  {
    path: "/login",
    name: "Login",
    component: () => import("../login/login.vue"),
  },
  {
    path: "/sk",
    name: "SK",
    component: () => import("../cutto/sk/sk.vue"),
  },
  {
    path: "/newhome",
    name: "newhome",
    component: () => import("../views/Home.vue"),
  },
  {
    component: Layout,
    redirect: "/newhome",
    children: [
      // {
      //   path: "/home",
      //   component: () => import("../views/Homem.vue"),
      // },
      {
        path: "/vup",
        component: () => import("../views/vup/vup.vue"),
        children: [],
      },
      {
        path: "/vup/vupdetail",
        component: () => import("../views/vup/vupdetail/vupdetail2.vue"),
      },
      {
        path: "/forum",
        component: () => import("../views/forum/forum.vue"),
      },
      {
        path: "/hothouse",
        component: () => import("../views/hothouse/hothouse.vue"),
      },
      {
        path: "/suggest",
        component: () => import("../views/suggest/suggest.vue"),
      },

      {
        path: "/searchd",
        component: () => import("../views/searchDetail/searchDetail.vue"),
      },
      {
        path: "/acmusic",
        component: () => import("../views/activity/acmusic/index.vue"),
      },
      {
        path: "/acmusic/applypage",
        component: () => import("../views/activity/acmusic/page/applypage.vue"),
      },
      {
        path: "/ddcfcheck",
        component: () => import("../views/cfcheck/cfcheck.vue"),
      },
    ],
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../cutto/404/index.vue"),
  },

  // {
  //   path: "/about",
  //   name: "About",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // to, from, next
  if (to.path == "/suggest") {
    if (store.state.Login.userinfo.role === 0) {
      next();
    } else {
      router.push("/404");
    }
  } else {
    next();
  }
});

export default router;
