<template>
  <div class="logincom">
    <div class="form-structor">
      <div :class="[isactive ? 'signup' : 'signup slide-up']">
        <h2 class="form-title" id="signup" @click="switchhandle">
          <span>或</span>注册
        </h2>
        <div class="form-holder">
          <!-- <input type="text" class="input" placeholder="用户名" /> -->
          <a-form
            :model="formState"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 16 }"
            autocomplete="off"
            :bodyStyle="{
              background: '#d8b4fe',
            }"
          >
            <a-form-item
              label="UID"
              name="zhuceuid"
              class="mt10"
              :rules="[{ required: true, message: '请输入uid' }]"
            >
              <a-input v-model:value="formState.username" placeholder="UID" />
            </a-form-item>

            <a-form-item name="zhucesf" label="身份">
              <a-radio-group v-model:value="formState['zhucesf']">
                <a-radio value="1">我是主播</a-radio>
                <a-radio value="4">我是dd</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 5, span: 16 }">
              <a-button type="primary" html-type="submit" class="btn submit-btn"
                >注册</a-button
              >
            </a-form-item>
          </a-form>
        </div>
        <p class="spacecolor">注册功能暂未开放</p>
        <!-- <button class="submit-btn"></button> -->
      </div>
      <div :class="[isactive ? 'login slide-up' : 'login']">
        <div class="center">
          <h2 class="form-title" id="login" @click="switchhandle">
            <span>或</span>登录
          </h2>

          <div class="form-holder">
            <a-form
              :model="formState"
              name="basic"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 16 }"
              autocomplete="off"
              :bodyStyle="{
                background: '#d8b4fe',
              }"
              @finish="onFinish"
              @finishFailed="onFinishFailed"
            >
              <a-form-item
                label="账号"
                name="username"
                :rules="[{ required: true, message: '请输入账号' }]"
              >
                <a-input
                  v-model:value="formState.username"
                  placeholder="账号"
                />
              </a-form-item>

              <a-form-item
                label="密码"
                name="password"
                :rules="[{ required: true, message: '请输入密码' }]"
              >
                <a-input-password
                  v-model:value="formState.password"
                  placeholder="密码"
                />
              </a-form-item>
              <a-form-item :wrapper-col="{ offset: 5, span: 16 }">
                <a-button
                  type="primary"
                  html-type="submit"
                  class="btn submit-btn"
                  >登录</a-button
                >
              </a-form-item>
            </a-form>
          </div>
          <!-- <button class="submit-btn" @click="loginhandle">登录</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import { vuplogin } from "../../../api/login/login";
import { useStore } from "vuex";
export default defineComponent({
  setup() {
    const formState = reactive({
      username: "",
      password: "",
      remember: true,
      zhuceuid: "",
      zhucesf: "4",
    });
    const store = useStore();
    const isactive = ref(false);
    const onFinish = (values) => {
      console.log("Success:", values);
      loginhandle();
    };

    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
    async function loginhandle() {
      let { code, data } = await vuplogin({
        userName: formState.username,
        passWord: formState.password,
      });
      if (code === 200) {
        store.commit("Login/SET_ISLOGIN", true);
        store.commit("Login/saveUserinfo", data);
        store.commit("common/setVisible", false);
        store.dispatch("Login/getUserInfos");
      }
    }
    function switchhandle() {
      isactive.value = !isactive.value;
    }

    return {
      isactive,
      formState,
      onFinish,
      onFinishFailed,
      loginhandle,
      switchhandle,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "./style/login.scss";

.logincom {
  margin-top: 30px;
  .spacecolor {
    color: white;
  }
}
</style>
