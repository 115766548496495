<template>
  <div class="header">
    <a-layout-header class="flexmode bg">
      <div class="logo">
        <img :src="Logo" alt="" draggable="false" />
      </div>
      <a-menu
        v-model:selectedKeys="selectedKeys"
        mode="horizontal"
        :style="{
          lineHeight: '64px',
        }"
      >
        <a-menu-item
          v-for="item in harr"
          :key="item.key"
          @click="jumprouter(item.key)"
        >
          <a-badge :count="item.count">{{ item.title }}</a-badge>
        </a-menu-item>
      </a-menu>
      <div class="setarea">
        <div class="button_click" @click="loginhandle" v-if="!islogin">
          登录/注册
        </div>
        <div v-else>
          <Avatar />
        </div>
      </div>
    </a-layout-header>
    <Loginpage />
  </div>
</template>
<script>
import { computed, reactive, toRefs, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Avatar from "./avatar.vue";
import Logo from "../../assets/logo.png";
import Loginpage from "../../components/loginpage/index.vue";
export default {
  components: {
    Avatar,
    Loginpage,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const islogin = computed(() => store.state.Login.islogin);
    const selectedKeys = ref(["/vup"]);
    const data = reactive({
      harr: [
        {
          title: "首页",
          key: "/newhome",
          id: 1,
          count: 0,
        },
        {
          title: "VUP",
          key: "/vup",
          id: 2,
          count: 0,
        },
        // {
        //   title: "繁星唱见",
        //   key: "/acmusic",
        //   id: 3,
        //   count: 0,
        // },
        // {
        //   title: "DD查查",
        //   key: "/ddcfcheck",
        //   id: 4,
        //   count: 0,
        // },
        {
          title: "开发者中心",
          key: "/suggest",
          id: 5,
          count: 0,
        },
      ],
    });

    function jumprouter(rou) {
      router.push(rou);
    }
    function loginhandle() {
      store.commit("common/setVisible", true);
    }
    return {
      islogin,
      Logo,
      selectedKeys,
      ...toRefs(data),
      jumprouter,
      loginhandle,
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  .bg {
    background: white;
    // background: linear-gradient(#16161d, #1f1f3a, #3b2f4a);
  }
  .setarea {
    display: flex;
    flex: 1;
    justify-content: end;
    align-items: center;
  }
  .logo {
    display: inline-block;
    margin-right: 20px;
    img {
      width: 120px;
      height: 50px;
    }
  }
}

.opt span {
  position: absolute;
  z-index: 5;
  top: 57px;
  left: 0px;
  width: 120px;
  height: 5px;
  border-radius: 5px;
  background: linear-gradient(90deg, transparent, #ff00cc);
  // background: #ff00cc;
  animation: animate2 4s linear infinite;
}
@keyframes animate2 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}
</style>
