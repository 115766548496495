import intervace from "../../utils/request.js";
// add添加vup
export const addvup = (data) => {
  return intervace({
    url: "/vup/addVup",
    method: "post",
    data,
  });
};

// 同步个人信息
export const syncUserInfo = (data) => {
  return intervace({
    url: "userInfo/syncUserInfo/ ",
    method: "post",
    data,
  });
};
// vup列表
export const vuplist = (data) => {
  return intervace({
    url: "vup/findVup",
    method: "post",
    data,
  });
};
//首页数据
export const gethomelist = (params = {}) => {
  return intervace({
    url: "/vup/getList",
    method: "get",
    params,
  });
};
// 首页搜索
// /vup/findVupName
export const findVupName = (params = {}) => {
  return intervace({
    url: "/vup/findVupName",
    method: "get",
    params,
  });
};
//
