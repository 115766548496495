import { createStore } from "vuex";

import Login from "./modules/login";
import common from "./modules/common";
export default createStore({
  modules: {
    Login,
    common,
  },
});
