import intervace from "../../utils/request.js";

// 登录
export const vuplogin = (data = {}) => {
  return intervace({
    url: "vupUser/login",
    method: "post",
    data,
  });
};
// 退出登录

export const vuploginout = (data = {}) => {
  return intervace({
    url: "vupUser/loginout",
    method: "post",
    data,
  });
};

//获取个人信息
export const getUserInfo = (params = {}) => {
  return intervace({
    url: "userInfo/getUserInfo",
    method: "get",
    params,
  });
};
