<template>
  <div class="Avatararea">
    <a-dropdown :trigger="['click']" placement="bottom">
      <a-avatar size="large" :src="avauserinfo.headUrl">
        <!-- <template #icon><UserOutlined /></template> -->
        <!-- <a-avatar  /> -->
      </a-avatar>

      <template #overlay>
        <a-menu>
          <a-menu-item key="1" @click="showModal">个人资料</a-menu-item>
          <a-menu-divider />
          <a-menu-item key="2" @click="logout">退出登录</a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>

    <a-modal
      v-model:visible="visible"
      :footer="null"
      destroyOnClose
      @ok="handleOk"
    >
      <div class="flexmode infoarea mt20">
        <figure class="snip0056">
          <figcaption v-if="avauserinfo.midId">
            <h2>
              <span>{{ avauserinfo.nickName }}</span>
            </h2>
            <p>
              {{ avauserinfo.personalSign }}
            </p>
          </figcaption>
          <img
            v-if="avauserinfo.midId"
            :src="avauserinfo.headUrl"
            :style="{
              minHeight: '150px',
            }"
          />

          <div class="position">
            <p v-if="avauserinfo.midId" class="infoccc">信息已同步</p>
            <div v-else class="flexmode">
              <div>信息未同步</div>
              <a-input v-model:value="midId" placeholder="uid" /><a-button
                type="primary"
                @click="holdin"
                >同步</a-button
              >
            </div>
          </div>
        </figure>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { computed, ref } from "vue";
// import { UserOutlined } from "@ant-design/icons-vue";
// import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { syncUserInfo } from "../../api/vup/vup";
import { vuploginout } from "../../api/login/login";
export default {
  components: {
    // UserOutlined,
  },
  setup() {
    // const router = useRouter();
    const store = useStore();
    const userinfo = computed(() => store.state.Login.userinfo);
    const avauserinfo = computed(() => store.state.Login.avauserinfo);

    const visible = ref(false);
    const midId = ref("");

    function showModal() {
      visible.value = true;
    }

    function handleOk() {
      visible.value = false;
    }
    async function holdin() {
      let { code } = await syncUserInfo({
        midId: midId.value,
        userId: userinfo.value.id,
      });
      if (code === 200) {
        message.success("同步成功");
        store.dispatch("Login/getUserInfos");
      }
    }
    async function logout() {
      let { code } = await vuploginout({});
      if (code === 200) {
        store.commit("Login/loginOut");
      }
      // router.push("/login");
    }

    return {
      userinfo,
      avauserinfo,
      visible,
      midId,
      logout,
      showModal,
      handleOk,
      holdin,
    };
  },
};
</script>
<style lang="scss" scoped>
.infoarea {
  padding: 10px;
  // border: solid 1px #d8b4fe;
  border-radius: 10px;
  box-shadow: 0px 17.5px 4.5px rgba(0, 0, 0, 0.056),
    0px 24.8px 11.3px rgba(0, 0, 0, 0.08), 0px 31px 23px rgba(0, 0, 0, 0.1),
    0px 39.9px 47.5px rgba(0, 0, 0, 0.124), 0px 67px 130px rgba(0, 0, 0, 0.18);
}
.left {
  border: solid 1px #ccc;
}
.right {
  .info {
    height: 80%;
  }
  .infop1 {
    span {
      font-size: 20px;
      font-weight: 600;
    }
  }
}
.infoccc {
  color: #ff0033;
}
.Avatararea {
}
</style>
