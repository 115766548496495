<template>
  <div class="loginpage">
    <a-modal
      v-model:visible="loginvisible"
      :footer="null"
      destroyOnClose
      @cancel="cnacelhandle"
      wrapClassName="spacestyle"
    >
      <div class="loarea">
        <LogincomVue />
      </div>
    </a-modal>
  </div>
</template>
<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
// import Clogin from "./components/login.vue";
import LogincomVue from "./components/logincom.vue";
export default {
  components: {
    // Clogin,
    LogincomVue,
  },
  setup() {
    const store = useStore();
    const loginvisible = computed(() => store.state.common.loginvisible);
    const activeKey = ref("1");
    function cnacelhandle() {
      store.commit("common/setVisible", false);
    }
    return {
      activeKey,
      loginvisible,
      cnacelhandle,
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep .ant-tabs-tab {
  font-size: 20px;
}
::v-deep .ant-modal-content {
  border-radius: 10px;
}
.spacestyle {
  border-radius: 10px;
}
.loginpage {
  .loarea {
  }
}
</style>
