const state = {
  loginvisible: false,
};
const mutations = {
  setVisible(state, paylod) {
    state.loginvisible = paylod;
  },
};
const actions = {};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
